<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <v-layout align-center justify-center>
      <div class="size">
        Your Purchase History
      </div>
    </v-layout>
    <divide-content :my-style="style" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        You will find your purchase history below.
        <br>
        <br>
      </v-flex>
    </v-layout>
    <v-app id="inspire">
      <v-data-table
        :headers="headers"
        :items="tableBody"
        :total-items="tableBody.length"
        :rows-per-page-items="[5, 10, 15, 20]"
        hide-action
        item-key="title"
        class="text-h3 elevation-1"
      >
        <template slot="items" slot-scope="props">
          <tr @click="props.expanded = !props.expanded">
            <td>{{ props.item.sno }}</td>
            <td>{{ props.item.orderid }}</td>
            <td>{{ props.item.course }} | {{ props.item.courseTitle }}</td>
            <td>{{ props.item.billingdate }}</td>
            <td>{{ props.item.nextpaymentdate }}</td>
            <td>$ {{ props.item.amount }}</td>
          </tr>
        </template>

        <template slot="expand" slot-scope="props">
          <v-card flat>
            <v-card-title class="headline">
              {{ props.item.title }}
            </v-card-title>
            <v-card-text>
              <v-layout row>
                {{ props.item.description }}
                <v-spacer />
                <v-img :src="props.item.poster" :lazy-src="lazy">
                  <v-layout />
                </v-img>
              </v-layout>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-app>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
import DivideContent from './Divider.vue'
export default {
  name: "UserDashboard",
  components: {
    VueTabs,
    VTab,
    DivideContent
  },
  props: {
      myStyle: {
          type: Object,
          default: () => {layout: "margin: 10px"}
      },},
  data() {
    return {
      style: {layout: "margin: 10px"},
      headers: [
        { text: "Serial #", value: "sno" },
        { text: "Order Id",value: "orderid"},
        { text: "Course",value: "course"},
        { text: "Paid Date", value: "billingdate" },
        { text: "Payment End Date", value: "nextpaymentdate" },
        { text: "Amount", value: "amount" },
      ],
      tableBody: [],
      lazy: `https://goo.gl/jbJWmK`,
    };
  },
  async mounted() {
    try{
      if (localStorage.getItem("token")) {
        const response = await axios.get(process.env.VUE_APP_API+'user/'+localStorage.getItem('userId')+'/courses',{ headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
        if(response.data.success){
            console.log("data:", response.data.data)
            let dataArr = response.data.data
            for(let i in dataArr){
              this.tableBody.push({ sno: parseInt(i)+1, orderid: dataArr[i].subscription.stripe_id, billingdate: dataArr[i].subscription.current_period_start , nextpaymentdate: dataArr[i].subscription.current_period_end, amount: dataArr[i].subscription.price.sale_price, course:  dataArr[i].subscription.course[0].category_title, courseTitle: dataArr[i].subscription.course[0].title})
            }
        } else {
          this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error!!',
                    text: 'Something went wrong1!!'
          });
        }
      }
    } catch(error){
      console.log("error:", error)
      this.$notify({
                group: 'notify',
                type: 'error',
                title: 'Error!!',
                text: 'Something went wrong2!!'
      });
    }
  },
  created ()  {
  this.$store.commit('navigation/resetState');
  this.$store.commit('navigation/changeTitle', 'Purchase History');
  this.$store.commit('navigation/toggleshowhome', false);
  this.$store.commit('navigation/replaceFind', false);
  this.$store.commit('navigation/replaceLeftArrow', true);
  this.$store.commit('navigation/userDashboard', true);
  },
  methods: {
    format(date) {
      date = new Date(date);
      const day = `${date.getUTCDate()}`.padStart(2, "0");
      const month = `${date.getUTCMonth() + 1}`.padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/screen-sizes.scss';
.center {
  text-align: center;
}

.form-control {
  display: block;
  width: 50%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

h2.heading-title {
    margin-bottom: 20px;
}

.center {
  text-align:center;
}
th, td, tr, table {
border: 1px solid #a9a9a9;
text-align: center;
padding: 10px;
}
th.column.sortable.text-xs-left {
    text-align: center !important;
}
</style>
